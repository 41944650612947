import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { loadTree } from '../../../helpers/MenuTreeHelper';
import { HashLink as Link } from 'react-router-hash-link';
import { getApiUrl } from '../../../helpers/helperApp';




const url = window.location;

function MenuAdmin({ miNivel, activeMenuColor, confApp }){

    const [ actual, saveActual ] = useState(0);
    const [ open1, saveOpen1 ] = useState(false); 
    const [ open2, saveOpen2 ] = useState(false); 
    const [ open3, saveOpen3 ] = useState(false); 
    const [ open4, saveOpen4 ] = useState(false); 
    const [ open5, saveOpen5 ] = useState(false); 
    const [ open6, saveOpen6 ] = useState(false); 
    const [ open7, saveOpen7 ] = useState(false); 
    const [ open8, saveOpen8 ] = useState(false);
    const [ open9, saveOpen9 ] = useState(false); 


    useEffect(() => {

        loadTree();

        if( url.pathname.includes("/documentos") || url.pathname.includes("/tipdoc") || url.pathname.includes("/estdoc") || url.pathname.includes("/apartados") || url.pathname.includes("/edirap") ){
            saveActual(1);
        }
        if( url.pathname.includes("/stockubi") || url.pathname.includes("/stockmov") || url.pathname.includes("/prealertas") || url.pathname.includes("/stockart") || url.pathname.includes("/stockreu") ){
            saveActual(2);
        }
        if( url.pathname.includes("/articulos") || url.pathname.includes("/tipuni") ){
            saveActual(3);
        }
        if( url.pathname.includes("/agentes") || url.pathname.includes("/tipage") ){
            saveActual(4);
        }
        if( url.pathname.includes("/equipos") || url.pathname.includes("/tipobj") ){
            saveActual(5);
        }
        if( url.pathname.includes("/centros") || url.pathname.includes("/tipcen") || url.pathname.includes("/adjcentros") ){
            saveActual(6);
        }
        if( url.pathname.includes("/loginusu") || url.pathname.includes("/tipgf") || url.pathname.includes("/loginnvl") || url.pathname.includes("/permisosusu") ){
            saveActual(7);
        }
        if( url.pathname.includes("/tipadj") ){
            saveActual(8);
        }
        if( url.pathname.includes("/impstoart") ){
            saveActual(9);
        }


    },[])

    const handleOpen = (e, open, saveOpen, indice) => {

        if( open && indice ){
            let subMenu = document.querySelector(`#sub${indice}`);
            if( subMenu ){
                subMenu.classList.remove('openMenu');  
                subMenu.classList.add('closeMenu');             
            }
        }else if( !open && indice ){
            let subMenu = document.querySelector(`#sub${indice}`);
            if( subMenu ){
                subMenu.classList.remove('closeMenu');  
                subMenu.classList.add('openMenu');               
            }
        }
        saveOpen(!open);
    }

    return(        
              
        <nav className="mt-2">
            <ul className="nav nav-pills nav-sidebar flex-column  nav-child-indent" data-widget="treeview" data-api="tree" role="menu" data-accordion="true">
            <li className="nav-item">
                <NavLink to="/inicio" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} style={{paddingLeft:10}}  >
                    <i className={confApp.documentosenc.sidebarmenu.icono} />
                    <p>{confApp.documentostrab.sidebarmenu.titulo}</p>
                </NavLink>
            </li>
            <li className="nav-item">
                <NavLink to="/checklist" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} style={{paddingLeft : "10px"}} >
                    <i className={confApp.checklist.sidebarmenu.icono} />
                    <p>{confApp.checklist.sidebarmenu.titulo}</p>
                </NavLink>
            </li> 
            {
                miNivel <= confApp.documentos.panminlvl || miNivel <= confApp.tipdoc.panminlvl || miNivel <= confApp.estdoc.panminlvl || miNivel <= confApp.apartados.panminlvl || miNivel <= confApp.cladoc.panminlvl || miNivel <= confApp.tippri.panminlvl || miNivel <= confApp.edirap.panminlvl
                ?
                // <li className={ actual == 1 ? "nav-item menu-open " : "nav-item "} >
                    // <a href="#" className={ actual == 1 ? "nav-link active" : "nav-link"} style={{paddingLeft:10}} >
                <li className={ open1 === true || actual === 1 ? " nav-item2 menu-open " : " nav-item2 "}  onClick={ e => handleOpen(e, open1, saveOpen1, 1) } >
                    <a href="#" className={ actual == 1 ? "nav-link2 active" : "nav-link2"}  style={{paddingLeft:10}} >
                        <i className={confApp.documentos.sidebarmenu.icono}></i>
                        <p>
                            {confApp.documentos.sidebarmenu.titulo}
                            <i className="fas fa-angle-left right"></i>
                        </p>
                    </a>
                    <ul id="sub1" className="nav nav-treeview sub">
                        {
                            miNivel <= confApp.documentos.panminlvl
                            ?
                            <li className="nav-item" >
                                <NavLink to="/documentos" className="nav-link" activeClassName="active" activeStyle={{ backgroundColor: activeMenuColor }} >
                                    <i className={confApp.documentos.sidebarmenu.icono} style={{marginRight:5}} />
                                    <p>{confApp.documentos.sidebarmenu.titulo}</p>
                                </NavLink>
                            </li>  
                            :
                            null
                        }
                        {
                            miNivel <= confApp.edirap.panminlvl
                            ?
                            <li className="nav-item" >
                                <NavLink to="/edirap" className="nav-link" activeClassName="active" activeStyle={{ backgroundColor: activeMenuColor }} >
                                    <i className={confApp.edirap.sidebarmenu.icono} style={{marginRight:5}} />
                                    <p>{confApp.edirap.sidebarmenu.titulo}</p>
                                </NavLink>
                            </li>  
                            :
                            null
                        }
                        {
                            miNivel <= confApp.tipdoc.panminlvl
                            ?
                            <li className="nav-item">
                            <NavLink to="/tipdoc" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} >
                                <i className={confApp.tipdoc.sidebarmenu.icono} style={{marginRight:5}} />
                                <p>{confApp.tipdoc.sidebarmenu.titulo}</p>
                            </NavLink>
                            </li>  
                            :
                            null
                        }
                        {
                            miNivel <= confApp.estdoc.panminlvl
                            ?
                            <li className="nav-item">
                                <NavLink to="/estdoc" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} >
                                    <i className={confApp.estdoc.sidebarmenu.icono} style={{marginRight:5}} />
                                    <p>{confApp.estdoc.sidebarmenu.titulo}</p>
                                </NavLink>
                            </li>  
                            :
                            null
                        }
                        {
                            miNivel <= confApp.apartados.panminlvl
                            ?
                            <li className="nav-item">
                                <NavLink to="/apartados" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} >
                                    <i className={confApp.apartados.sidebarmenu.icono} style={{marginRight:5}} />
                                    <p>{confApp.apartados.sidebarmenu.titulo}</p>
                                </NavLink>
                            </li>  
                            :
                            null
                        }                                                        
                    </ul>
                </li>
                :
                null
            }
            {
                miNivel <= confApp.stockubi.panminlvl || miNivel <= confApp.stockmov.panminlvl || miNivel <= confApp.ubicaciones.panminlvl || miNivel <= confApp.prealertas.panminlvl || miNivel <= confApp.stockart.panminlvl || miNivel <= confApp.stockreu.panminlvl
                ?
                <li className={ open2 === true || actual === 2  ? " nav-item2 menu-open " : " nav-item2 "}  onClick={ e => handleOpen(e, open2, saveOpen2, 2) } >
                    <a href="#" className={ actual == 2  ? "nav-link2 active" : "nav-link2"}  style={{paddingLeft:10}} >
                        <i className={confApp.stockmov.sidebarmenuparent.icono}></i>
                        <p>
                            {confApp.stockmov.sidebarmenuparent.titulo}
                            <i className="fas fa-angle-left right"></i>
                        </p>
                    </a>
                    <ul id="sub2" className="nav nav-treeview sub">
                        {
                            miNivel <= confApp.stockubi.panminlvl
                            ?
                            <li className="nav-item">
                                <NavLink to="/stockubi" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} >
                                    <i className={confApp.stockubi.sidebarmenu.icono} style={{marginRight:5}} />
                                    <p>{confApp.stockubi.sidebarmenu.titulo}</p>
                                </NavLink>
                            </li>  
                            :
                            null
                        }
                        {
                            miNivel <= confApp.stockmov.panminlvl
                            ?
                            <li className="nav-item">
                                <NavLink to="/stockmov" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} >
                                    <i className={confApp.stockmov.sidebarmenu.icono} style={{marginRight:5}} />
                                    <p>{confApp.stockmov.sidebarmenu.titulo}</p>
                                </NavLink>
                            </li>  
                            :
                            null
                        }
                        {
                            miNivel <= confApp.stockreu.panminlvl
                            ?
                            <li className="nav-item">
                                <NavLink to="/stockreu" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} >
                                    <i className={confApp.stockreu.sidebarmenu.icono} style={{marginRight:5}} />
                                    <p>{confApp.stockreu.sidebarmenu.titulo}</p>
                                </NavLink>
                            </li>  
                            :
                            null
                        }
                        {
                            miNivel <= confApp.stockart.panminlvl
                            ?
                            <li className="nav-item">
                                <NavLink to="/stockart" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} >
                                <i className={confApp.stockart.sidebarmenu.icono} style={{marginRight:5}} />
                                <p>{confApp.stockart.sidebarmenu.titulo}</p>
                                </NavLink>
                            </li>  
                            :
                            null
                        }                     
                        {
                            miNivel <= confApp.prealertas.panminlvl
                            ?
                            <li className="nav-item">
                                <NavLink to="/prealertas" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} >
                                <i className={confApp.prealertas.sidebarmenu.icono} style={{marginRight:5}} />
                                <p>{confApp.prealertas.sidebarmenu.titulo}</p>
                                </NavLink>
                            </li>  
                            :
                            null
                        
                        }                      
                    </ul>
                </li>
                :
                null
            }
            {
                miNivel <= confApp.articulos.panminlvl || miNivel <= confApp.tipuni.panminlvl || miNivel <= confApp.clauni.panminlvl
                ?
                <li className={ open3 === true || actual === 3 ? " nav-item2 menu-open " : " nav-item2 "}  onClick={ e => handleOpen(e, open3, saveOpen3, 3) } >
                    <a href="#" className={ actual == 3 ? "nav-link2 active" : "nav-link2"}  style={{paddingLeft:10}} >
                        <i className={confApp.articulos.sidebarmenu.icono}></i>
                        <p>
                            {confApp.articulos.sidebarmenu.titulo}
                            <i className="fas fa-angle-left right"></i>
                        </p>
                    </a>
                    <ul id="sub3" className="nav nav-treeview sub">
                        {
                            miNivel <= confApp.articulos.panminlvl
                            ?
                            <li className="nav-item">
                                <NavLink to="/articulos" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} >
                                    <i className={confApp.articulos.sidebarmenu.icono} style={{marginRight:5}} />
                                    <p>{confApp.articulos.sidebarmenu.titulo}</p>
                                </NavLink>
                            </li>  
                            :
                            null
                        }
                        {
                            miNivel <= confApp.tipuni.panminlvl
                            ?
                            <li className="nav-item">
                            <NavLink to="/tipuni" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} >
                                <i className={confApp.tipuni.sidebarmenu.icono} style={{marginRight:5}} />
                                <p>{confApp.tipuni.sidebarmenu.titulo}</p>
                            </NavLink>
                            </li>  
                            :
                            null
                        }                        
                    </ul>
                </li>
                :
                null
            }
            {
                miNivel <= confApp.agentes.panminlvl || miNivel <= confApp.tipage.panminlvl || miNivel <= confApp.claage.panminlvl
                ?
                <li className={ open4 === true || actual === 4 ? " nav-item2 menu-open " : " nav-item2 "}  onClick={ e => handleOpen(e, open4, saveOpen4, 4) } >
                    <a href="#" className={ actual == 4 ? "nav-link2 active" : "nav-link2"}  style={{paddingLeft:10}} >
                        <i className={confApp.agentes.sidebarmenu.icono}></i>
                        <p>
                            {confApp.agentes.sidebarmenu.titulo}
                            <i className="fas fa-angle-left right"></i>
                        </p>
                    </a>
                    <ul id="sub4" className="nav nav-treeview sub">
                        {
                            miNivel <= confApp.agentes.panminlvl
                            ?
                            <li className="nav-item">
                                <NavLink to="/agentes" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} >
                                <i className={confApp.agentes.sidebarmenu.icono} style={{marginRight:5}} />
                                <p>{confApp.agentes.sidebarmenu.titulo}</p>
                                </NavLink>
                            </li>  
                            :
                            null
                        }
                        {
                            miNivel <= confApp.tipage.panminlvl
                                ?
                                <li className="nav-item">
                                <NavLink to="/tipage" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} >
                                    <i className={confApp.tipage.sidebarmenu.icono} style={{marginRight:5}} />
                                    <p>{confApp.tipage.sidebarmenu.titulo}</p>
                                </NavLink>
                                </li>  
                                :
                                null
                        }                        
                    </ul>
                </li>
                :
                null
            }
            {
                miNivel <= confApp.equipos.panminlvl || miNivel <= confApp.tipobj.panminlvl || miNivel <= confApp.apartados.panminlvl
                ?
                <li className={ open5 === true || actual === 5 || url.pathname.includes("/apartados") ? " nav-item2 menu-open " : " nav-item2 "}  onClick={ e => handleOpen(e, open5, saveOpen5, 5) } >
                    <a href="#" className={ actual == 5 || url.pathname.includes("/apartados") ? "nav-link2 active" : "nav-link2"}  style={{paddingLeft:10}} >
                        <i className={confApp.equipos.sidebarmenu.icono}></i>
                        <p>
                            {confApp.equipos.sidebarmenu.titulo}
                            <i className="fas fa-angle-left right"></i>
                        </p>
                    </a>
                    <ul id="sub5" className="nav nav-treeview sub">
                    {
                        miNivel <= confApp.equipos.panminlvl
                        ?
                        <li className="nav-item">
                            <NavLink to="/equipos" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} >
                                <i className={confApp.equipos.sidebarmenu.icono} style={{marginRight:5}} />
                                <p>{confApp.equipos.sidebarmenu.titulo}</p>
                            </NavLink>
                        </li>  
                        :
                        null
                    }
                    {
                        miNivel <= confApp.tipobj.panminlvl
                        ?
                        <li className="nav-item">
                        <NavLink to="/tipobj" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} >
                            <i className={confApp.tipobj.sidebarmenu.icono} style={{marginRight:5}} />
                            <p>{confApp.tipobj.sidebarmenu.titulo}</p>
                        </NavLink>
                        </li>  
                        :
                        null
                    }
                    {
                        miNivel <= confApp.apartados.panminlvl
                        ?
                        <li className="nav-item">
                            <NavLink to="/apartados" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} >
                                <i className={confApp.apartados.sidebarmenu.icono} style={{marginRight:5}} />
                                <p>{confApp.apartados.sidebarmenu.titulo}</p>
                            </NavLink>
                        </li>  
                        :
                        null
                    }
                    </ul>
                </li>
                :
                null
            }
            {
                miNivel <= confApp.ubicaciones.panminlvl
                ?
                <li className="nav-item">
                    <NavLink to="/ubicaciones" className={ url.pathname.includes("/ubicaciones") ? "nav-link active" : "nav-link"} activeStyle={{ backgroundColor: activeMenuColor }} style={{paddingLeft:10}}  >
                        <i className={confApp.ubicaciones.sidebarmenu.icono} />
                        <p>{confApp.ubicaciones.sidebarmenu.titulo}</p>
                    </NavLink>
                </li>  
                :
                null
            }
            {
                miNivel <= confApp.centros.panminlvl || miNivel <= confApp.tipcen.panminlvl || miNivel <= confApp.ubicaciones.panminlvl  ?
                <li className={ open6 === true || actual === 6 ? " nav-item2 menu-open " : " nav-item2 "}  onClick={ e => handleOpen(e, open6, saveOpen6, 6) } >
                    <a href="#" className={ actual == 6 ? "nav-link2 active" : "nav-link2"}  style={{paddingLeft:10}} >
                        <i className={confApp.centros.sidebarmenu.icono}></i>
                        <p>
                            {confApp.centros.sidebarmenu.titulo}
                            <i className="fas fa-angle-left right"></i>
                        </p>
                    </a>
                    <ul id="sub6" className="nav nav-treeview sub">
                        {
                            miNivel <= confApp.centros.panminlvl
                            ?
                            <li className="nav-item">
                                <NavLink to="/centros" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} >
                                    <i className={confApp.centros.sidebarmenu.icono} style={{marginRight:5}} />
                                    <p>{confApp.centros.sidebarmenu.titulo}</p>
                                </NavLink>
                            </li>  
                            :
                            null
                        }
                        {
                            miNivel <= confApp.tipcen.panminlvl
                            ?
                            <li className="nav-item">
                            <NavLink to="/tipcen" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} >
                                <i className={confApp.tipcen.sidebarmenu.icono} style={{marginRight:5}} />
                                <p>{confApp.tipcen.sidebarmenu.titulo}</p>
                            </NavLink>
                            </li>  
                            :
                            null
                        }
                        {
                            miNivel <= confApp.centrosadj.panminlvl
                            ?
                            <li className="nav-item">
                            <NavLink to="/adjcentros" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} >
                                <i className={confApp.centrosadj.sidebarmenu.icono} style={{marginRight:5}} />
                                <p>{confApp.centrosadj.sidebarmenu.titulo}</p>
                            </NavLink>
                            </li>  
                            :
                            null
                        }                    
                    </ul>
                </li>
                :
                null
            }
            {
                miNivel <= confApp.loginusu.panminlvl || miNivel <= confApp.tipgf.panminlvl || miNivel <= confApp.loginnvl.panminlvl || miNivel <= confApp.permisosusu.panminlvl
                ?
                <li className={ open7 === true || actual === 7 ? " nav-item2 menu-open " : " nav-item2 "}  onClick={ e => handleOpen(e, open7, saveOpen7, 7) } >
                    <a href="#" className={ actual == 7 ? "nav-link2 active" : "nav-link2"}  style={{paddingLeft:10}} >
                        <i className={confApp.loginusu.sidebarmenu.icono}></i>
                        <p>
                            {confApp.loginusu.sidebarmenu.titulo}
                            <i className="fas fa-angle-left right"></i>
                        </p>
                    </a>
                    <ul id="sub7" className="nav nav-treeview sub">
                        {
                            miNivel <= confApp.loginusu.panminlvl
                            ?
                            <li className="nav-item">
                                <NavLink to="/loginusu" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} >
                                    <i className={confApp.loginusu.sidebarmenu.icono} style={{marginRight:5}} />
                                    <p>{confApp.loginusu.sidebarmenu.titulo}</p>
                                </NavLink>
                            </li>
                            :
                            null
                        }
                        {
                            miNivel <= confApp.tipgf.panminlvl
                            ?
                            <li className="nav-item">
                            <NavLink to="/tipgf" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} >
                                <i className={confApp.tipgf.sidebarmenu.icono} style={{marginRight:5}} />
                                <p>{confApp.tipgf.sidebarmenu.titulo}</p>
                            </NavLink>
                            </li>  
                            :
                            null
                        }
                        {/* {
                            miNivel <= confApp.loginnvl.panminlvl
                            ?
                            <li className="nav-item">
                                <NavLink to="/loginnvl" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} >
                                    <i className={confApp.loginnvl.sidebarmenu.icono} style={{marginRight:5}} />
                                    <p>{confApp.loginnvl.sidebarmenu.titulo}</p>
                                </NavLink>
                            </li>
                            :
                            null
                        }
                        {
                            miNivel <= confApp.permisosusu.panminlvl
                            ?
                            <li className="nav-item">
                                <NavLink to="/permisosusu" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} >
                                    <i className={confApp.permisosusu.sidebarmenu.icono} style={{marginRight:5}} />
                                    <p>{confApp.permisosusu.sidebarmenu.titulo}</p>
                                </NavLink>
                            </li>
                            :
                            null
                        } */}
                    </ul>
                </li>
                :
                null
            }
            {
                miNivel <= confApp.tipadj.panminlvl
                ?
                <li className={ open8 === true || actual === 8 ? " nav-item2 menu-open " : " nav-item2 "}  onClick={ e => handleOpen(e, open8, saveOpen8, 8) } >
                    <a href="#" className={ actual == 8 ? "nav-link2 active" : "nav-link2"}  style={{paddingLeft:10}} >
                        <i className={confApp.appcfg.sidebarmenu.icono}></i>
                        <p>
                            {confApp.appcfg.sidebarmenu.titulo}
                            <i className="fas fa-angle-left right"></i>
                        </p>
                    </a>
                    <ul id="sub8" className="nav nav-treeview sub">
                    {
                        miNivel <= confApp.tipadj.panminlvl
                        ?
                        <li className="nav-item">
                            <NavLink to="/tipadj" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} >
                                <i className={confApp.tipadj.sidebarmenu.icono} style={{marginRight:5}} />
                                <p>{confApp.tipadj.sidebarmenu.titulo}</p>
                            </NavLink>
                        </li>
                        :
                        null
                    }                           
                    </ul>
                </li>
                :
                null
            }
            {
                miNivel <= confApp.kpis.panminlvl
                ?
                <li className="nav-item">
                    <NavLink to="/kpis" className={ url.pathname.includes("/kpis") ? "nav-link active" : "nav-link"} activeStyle={{ backgroundColor: activeMenuColor }} style={{paddingLeft:10}} >
                    <i className={confApp.kpis.sidebarmenu.icono} />
                    <p>{confApp.kpis.sidebarmenu.titulo}</p>
                    </NavLink>
                </li>  
                :
                null
            }
                <li className={ open9 === true || actual === 9 ? " nav-item2 menu-open " : " nav-item2 "}  onClick={ e => handleOpen(e, open9, saveOpen9, 9) } >
                        <a href="#" className={ actual == 9 ? "nav-link2 active" : "nav-link2"}  style={{paddingLeft:10}} >
                            <i className={confApp.menuadmin.impexp.icono}></i>
                            <p>
                                {confApp.menuadmin.impexp.titulo}
                                <i className="fas fa-angle-left right"></i>
                            </p>
                        </a>
                        <ul id="sub9" className="nav nav-treeview sub">
                            <li className="nav-item">
                                <NavLink to="/impstoart" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} >
                                <i className={confApp.impstoart.sidebarmenu.icono} style={{marginRight:5}} />
                                <p>{confApp.impstoart.sidebarmenu.titulo}</p>
                                </NavLink>
                            </li> 
                        </ul>
                </li>            
            </ul>
        </nav>                          

       

    )
}

export default MenuAdmin;